import { Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, ViewChild } from '@angular/core';
import { ModalService } from '../../common/modal/modal.service';
import { CommonModule } from '@angular/common';
import { Observable, Subscription, timer } from 'rxjs';
import { AuthService, currentUserInfo } from '../auth/auth.service';
import moment from 'moment';
import { OdometerDirective } from '@app/shared/lib/directive/odometer.directive';
import { LatestBetService } from '../../service/latest-bet.service';
import { Swiper, SwiperOptions } from 'swiper/types';
import { SwiperDirective } from '@app/shared/lib/directive/swiper.directive';
import { HighRollersService } from '../../service/high-rollers.service';
import { PromotionContestComponent } from "../promotion/promotion-contest.component";

@Component({
    selector: 'app-contest',
    standalone: true,
    imports: [
    CommonModule,
    OdometerDirective,
    SwiperDirective,
    PromotionContestComponent,
    PromotionContestComponent
],
    templateUrl: './contest.component.html',
    styles: ``,
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ContestComponent {
    private subs: Subscription[] = [];
    
    currentUser!:currentUserInfo | null;
    
    tab:string = 'latest_bets'

    latestBetOptions!:SwiperOptions
    latestBetItems!:any
    latestBetSwiper!:Swiper

    highRollersOptions!:SwiperOptions
    highRollersItems:any;
    highRollersSwiper!:Swiper
    
    @ViewChild('flipdownDiv') flipdownDiv!: ElementRef;

    get modal() { return this.modalService }
    constructor(
        private modalService: ModalService,
        private authService: AuthService,
        private latestBetService: LatestBetService,
        private highRollersService: HighRollersService
    ) {

        this.latestBetOptions = {
            direction: "vertical",
            slidesPerView: 10,
            autoplay:{
                delay: 300,
                disableOnInteraction: false,
            },
            on: {
                transitionEnd: (swiper) => {
                    // const { wrapperEl, activeIndex, slides } = swiper;
                    // const newHeight = slides[activeIndex].scrollHeight;
                    // swiper.wrapperEl.style.height = `${newHeight}px`;
                    // swiper.update();
                },
                init:(swiper: Swiper) => {
                    if(this.latestBetItems.length > 0) {
                        swiper.params.loop = true;
                        swiper.update()
                        swiper.autoplay.start();
                        console.log('1')
                    }
                    this.latestBetSwiper = swiper;                   
                }
            },
        };

        this.highRollersOptions = {
            direction: "vertical",
            slidesPerView: 10,
            autoplay:{
                delay: 300,
                disableOnInteraction: false,
            },
            on: {
                transitionEnd: (swiper) => {
                    // const { wrapperEl, activeIndex, slides } = swiper;
                    // const newHeight = slides[activeIndex].scrollHeight;
                    // swiper.wrapperEl.style.height = `${newHeight}px`;
                    // swiper.update();
                },
                init:(swiper: Swiper) => {
                    if(this.highRollersItems.length > 0) {
                        swiper.params.loop = true;
                        swiper.update()
                        swiper.autoplay.start()
                        console.log('2')
                    }
                    this.highRollersSwiper = swiper;
                }
            },
        };
    }

    ngOnInit() {
        this.subs.push(
            this.latestBetService.getData().subscribe(v => {
                this.latestBetItems = v;
                console.log('3')
                if(this.latestBetSwiper) {
                    this.latestBetSwiper.params.loop = true;
                    console.log('4')
                    this.latestBetSwiper.autoplay.start()
                }
            })
        )

        this.subs.push(
            this.highRollersService.getData().subscribe(v => {
                this.highRollersItems = v;
                // console.log('5')
                if(this.highRollersSwiper) {
                    this.highRollersSwiper.params.loop = true;
                    this.highRollersSwiper.autoplay.start()
                    // console.log('6')
                }
            })
        )
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }

    onTab(tab:string){
        this.tab = tab;
        console.log('7')
        if(tab == 'latest_bets') {
            console.log('8')
            if(this.latestBetSwiper && this.latestBetItems.length > 0) {
                this.latestBetSwiper.params.loop = true;
                this.latestBetSwiper.update()
                this.latestBetSwiper.autoplay.start()
                console.log('9')
            }
        } else if(tab == 'high_rollers') {
            console.log('10')
            if(this.highRollersSwiper && this.highRollersItems.length > 0) {
                this.highRollersSwiper.params.loop = true;
                this.highRollersSwiper.update()
                this.highRollersSwiper.autoplay.start()
                console.log('11')
                // this.highRollersSwiper.update();
            }
        }
        
    }

    GetGamesIcon(code:string, game_name:string='') {
        if(code == 'BET_CONSTRUCT') return '/assets/images/category/sports.svg';
        else if(code == 'BETRADAR') {
            if(game_name.startsWith('958:esport')) return '/assets/images/category/esports.svg'
            else return '/assets/images/category/sports.svg'
        }else if(code == 'CASINO' || code == 'EVOLUTION') return '/assets/images/category/casino.svg';
        else if(code == 'MINIGAME' || code == 'WP') return '/assets/images/category/minigame.svg'
        else console.log(code)
        return ''
    }
}
